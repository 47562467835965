import { render, staticRenderFns } from "./teacherleave.vue?vue&type=template&id=c1ee98a6"
import script from "./teacherleave.vue?vue&type=script&lang=js"
export * from "./teacherleave.vue?vue&type=script&lang=js"
import style0 from "./teacherleave.vue?vue&type=style&index=0&id=c1ee98a6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_ckru7f3yypsoan6edyu23zfbiq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports