import Vue from 'vue'
import Router from 'vue-router'
import login from '@/view/login/index'
Vue.use(Router)
const originalPush = Router.prototype.push
   Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
export default new Router({
	routes: [
		{
			path: '/',
			name: 'login',
			component: login,
			meta: {
				title: '登录'
			}
		},
		{
			path: '/archives_index',
			name: 'archives_index',
			component: () => import('@/view/archives/index'),
			meta: {
				title: '教师档案'
			}
		},
		{
			path: '/archives_details',
			name: 'archives_details',
			component: () => import('@/view/archives/details'),
			meta: {
				title: '教师档案'
			}
		},
		{
			path: '/paike_index',
			name: 'paike_index',
			component: () => import('@/view/paike/index'),
			meta: {
				title: '教师排课'
			}
		},
		{
			path: '/deploy',
			name: 'deploy',
			component: () => import('@/view/paike/deploy'),
			meta: {
				title: '排课'
			}
		},
		{
			path: '/station',
			name: 'station',
			component: () => import('@/view/station/index'),
			meta: {
				title: '岗位安排'
			}
		},
		{
			path: '/student',
			name: 'student',
			component: () => import('@/view/student/index'),
			meta: {
				title: '学生档案'
			}
		},
		{
			path: '/sign',
			name: 'sign',
			component: () => import('@/view/sign/index'),
			meta: {
				title: '签到列表'
			}
		},
		{
			path: '/schedule',
			name: 'schedule',
			component: () => import('@/view/sign/schedule'),
			meta: {
				title: '签到列表'
			}
		},
		{
			path: '/studentDetails',
			name: 'studentDetails',
			component: () => import('@/view/student/details'),
			meta: {
				title: '学生档案'
			}
		},
		{
			path: '/index',
			name: 'index',
			component: () => import('@/view/index/index'),
			meta: {
				title: '首页'
			}
		},
		{
			path: '/manageSchool',
			name: 'manageSchool',
			component: () => import('@/view/manage/school/index'),
			meta: {
				title: '校区管理'
			}
		},
		{
			path: '/manageSection',
			name: 'manageSection',
			component: () => import('@/view/manage/section/index'),
			meta: {
				title: '部门设置'
			}
		},
		{
			path: '/managePeople',
			name: 'managePeople',
			component: () => import('@/view/manage/people/index'),
			meta: {
				title: '人员管理'
			}
		},
		{
			path: '/manageSubject',
			name: 'manageSubject',
			component: () => import('@/view/manage/subject/index'),
			meta: {
				title: '学科设置'
			}
		},
		{
			path: '/manageCourse',
			name: 'manageCourse',
			component: () => import('@/view/manage/course/index'),
			meta: {
				title: '课程时段'
			}
		},
		{
			path: '/managePlace',
			name: 'managePlace',
			component: () => import('@/view/manage/place/index'),
			meta: {
				title: '场地设置'
			}
		},
		{
			path: '/grade',
			name: 'grade',
			component: () => import('@/view/setting/grade'),
			meta: {
				title: '年级班级'
			}
		},
		{
			path: '/student_management',
			name: 'student_management',
			component: () => import('@/view/manage/student'),
			meta: {
				title: '学籍管理'
			}
		},
		{
			path: '/semester',
			name: 'semester',
			component: () => import('@/view/manage/semester/index'),
			meta: {
				title: '学年学期'
			}
		},
		{
			path: '/divide',
			name: 'divide',
			component: () => import('@/view/setting/divide-class'),
			meta: {
				title: '学生分班'
			}
		},
		{
			path: '/divide_detail',
			name: 'divide_detail',
			component: () => import('@/view/setting/student_placement_detail'),
			meta: {
				title: '分班详情'
			}
		},
		{
			path: '/exam',
			name: 'exam',
			component: () => import('@/view/manage/exam/index'),
			meta: {
				title: '考试管理'
			}
		},
		{
			path: '/exam2',
			name: 'exam2',
			component: () => import('@/view/manage/exam/exam2'),
			meta: {
				title: '考试成绩'
			}
		},
		{
			path: '/exam_detail',
			name: 'exam_detail',
			component: () => import('@/view/manage/exam/exam_detail'),
			meta: {
				title: '成绩详情'
			}
		},
		{
			path: '/stationDetails',
			name: 'stationDetails',
			component: () => import('@/view/station/details'),
			meta: {
				title: '岗位配置'
			}
		},
		{
			path: '/admit',
			name: 'admit',
			component: () => import('@/view/manage/admit/index'),
			meta: {
				title: '招生管理'
			}
		},
		{
			path: '/rule',
			name: 'rule',
			component: () => import('@/view/paike/rule'),
			meta: {
				title: '排课规则'
			}
		},
		{
			path: '/dormitory',
			name: 'dormitory',
			component: () => import('@/view/manage/dormitory/dormitory'),
			meta: {
				title: '住宿管理'
			}
		},
		{
			path: '/coursePatrol',
			name: 'coursePatrol',
			component: () => import('@/view/course_patrol/index'),
			meta: {
				title: '晨午检'
			}
		},
		{
			path: '/setEvaluate',
			name: 'setEvaluate',
			component: () => import('@/view/manage/setEvaluate/index'),
			meta: {
				title: '评价配置'
			}
		},
		{
			path: '/courseSystem',
			name: 'courseSystem',
			component: () => import('@/view/courseSystem/index'),
			meta: {
				title: '课程体系'
			}
		},
		{
			path: '/approvalProcess',
			name: 'approvalProcess',
			component: () => import('@/view/rule/approval_process/index'),
			meta: {
				title: '流程审批'
			}
		},
		{
			path: '/leave',
			name: 'leave',
			component: () => import('@/view/rule/leave/leave'),
			meta: {
				title: '请假审批'
			}
		},
		{
			path: '/notice',
			name: 'notice',
			component: () => import('@/view/manage/notice/index'),
			meta: {
				title: '学校公告'
			}
		},
		{
			path: '/myClass',
			name: 'myClass',
			component: () => import('@/view/index/myClass/myClass'),
			meta: {
				title: '我的班级'
			}
		},
		{
			path: '/myClassDetails',
			name: 'myClassDetails',
			component: () => import('@/view/index/myClass/details'),
			meta: {
				title: '我的班级'
			}
		},
		{
			path: '/myGrade',
			name: 'myGrade',
			component: () => import('@/view/index/myGrade/myGrade'),
			meta: {
				title: '我的年级'
			}
		},
		{
			path: '/CourseCourse',
			name: 'CourseCourse',
			component: () => import('@/view/index/CourseCourse/index'),
			meta: {
				title: '课程申报'
			}
		},
		{
			path: '/repair',
			name: 'repair',
			component: () => import('@/view/index/repair/index'),
			meta: {
				title: '校园报修'
			}
		},
		{
			path: '/safety',
			name: 'safety',
			component: () => import('@/view/rule/safety/index'),
			meta: {
				title: '安全隐患'
			}
		},
		{
			path: '/adjustClass',
			name: 'adjustClass',
			component: () => import('@/view/index/adjustClass/index'),
			meta: {
				title: '安全隐患'
			}
		},
		{
			path: '/asset',
			name: 'asset',
			component: () => import('@/view/manage/asset/index'),
			meta: {
				title: '资产管理'
			}
		},
		{
			path: '/supplies',
			name: 'supplies',
			component: () => import('@/view/manage/asset/supplies'),
			meta: {
				title: '资产管理'
			}
		},
		{
			path: '/fileIo',
			name: 'fileIo',
			component: () => import('@/view/fileIo/index'),
			meta: {
				title: '通知文件'
			}
		},
		{
			path: '/liucheng',
			name: 'liucheng',
			component: () => import('@/view/rule/index'),
			meta: {
				title: '流程审批'
			}
		},
		{
			path: '/print',
			name: 'print',
			component: () => import('@/view/rule/print/index'),
			meta: {
				title: '流程打印'
			}
		},
		{
			path: '/dorm',
			name: 'dorm',
			component: () => import('@/view/manage/dorm/index'),
			meta: {
				title: '宿舍管理'
			}
		},
		{
			path: '/dorm1',
			name: 'dorm1',
			component: () => import('@/view/manage/dorm/dorm1'),
			meta: {
				title: '宿舍管理'
			}
		},
		{
			path: '/dorm2',
			name: 'dorm3',
			component: () => import('@/view/manage/dorm/dorm2'),
			meta: {
				title: '宿舍管理'
			}
		},
		{
			path: '/meeting',
			name: 'meeting',
			component: () => import('@/view/rule/meeting'),
			meta: {
				title: '会议管理'
			}
		},
		{
			path: '/switching',
			name: 'switching',
			component: () => import('@/view/rule/switching'),
			meta: {
				title: '调课列表'
			}
		},{
			path: '/schoolCalendar',
			name: 'schoolCalendar',
			component: () => import('@/view/schoolCalendar/index'),
			meta: {
				title: '学校校历'
			}
		},{
			path: '/classHour',
			name: 'classHour',
			component: () => import('@/view/index/classHour/index'),
			meta: {
				title: '我的课时'
			}
		},{
			path: '/comment',
			name: 'comment',
			component: () => import('@/view/rule/comment'),
			meta: {
				title: '评语管理'
			}
		},{
			path: '/clockingIn',
			name: 'clockingIn',
			component: () => import('@/view/rule/clockingIn'),
			meta: {
				title: '考勤管理'
			}
		},{
			path: '/user',
			name: 'user',
			component: () => import('@/view/setting/user'),
			meta: {
				title: '人员管理'
			}
		},{
			path: '/statement',
			name: 'statement',
			component: () => import('@/view/statement/index'),
			meta: {
				title: '报表中心'
			}
		},{
			path: '/teacherlist',
			name: 'teacherlist',
			component: () => import('@/view/statement/teacherlist'),
			meta: {
				title: '教师数据列表'
			}
		},{
			path: '/studentList',
			name: 'studentList',
			component: () => import('@/view/statement/studentList'),
			meta: {
				title: '学生数据列表'
			}
		},{
			path: '/teacherCheck',
			name: 'teacherCheck',
			component: () => import('@/view/statement/teacherCheck'),
			meta: {
				title: '教师请假列表'
			}
		},{
			path: '/studentCheck',
			name: 'studentCheck',
			component: () => import('@/view/statement/studentCheck'),
			meta: {
				title: '学生请假列表'
			}
		},{
			path: '/work',
			name: 'work',
			component: () => import('@/view/flow/work'),
			meta: {
				title: '公文流转'
			}
		},{
			path: '/teacherleave',
			name: 'teacherleave',
			component: () => import('@/view/flow/teacherleave'),
			meta: {
				title: '我的请假'
			}
		},{
			path: '/activitylist',
			name: 'activitylist',
			component: () => import('@/view/flow/activitylist'),
			meta: {
				title: '活动申请'
			}
		},{
			path: '/goods',
			name: 'goods',
			component: () => import('@/view/flow/goods'),
			meta: {
				title: '物品领用'
			}
		},{
			path: '/evection',
			name: 'evection',
			component: () => import('@/view/flow/evection'),
			meta: {
				title: '公务出差'
			}
		},{
			path: '/danger',
			name: 'danger',
			component: () => import('@/view/flow/danger'),
			meta: {
				title: '安全隐患'
			}
		},{
			path: '/warning',
			name: 'warning',
			component: () => import('@/view/setting/warning'),
			meta: {
				title: '预警中心'
			}
		},{
			path: '/disciplineUser',
			name: 'disciplineUser',
			component: () => import('@/view/setting/disciplineUser'),
			meta: {
				title: '科组人员'
			}
		},{
			path: '/rewards',
			name: 'rewards',
			component: () => import('@/view/setting/rewards/index'),
			meta: {
				title: '奖惩管理'
			}
		},{
			path: '/studentrewards',
			name: 'studentrewards',
			component: () => import('@/view/setting/rewards/student'),
			meta: {
				title: '奖惩管理'
			}
		},{
			path: '/classRewards',
			name: 'classRewards',
			component: () => import('@/view/setting/rewards/classRewards'),
			meta: {
				title: '奖惩管理'
			}
		},{
			path: '/dormrewards',
			name: 'dormrewards',
			component: () => import('@/view/setting/rewards/dorm'),
			meta: {
				title: '奖惩管理'
			}
		},{
			path: '/purchase',
			name: 'purchase',
			component: () => import('@/view/flow/purchase'),
			meta: {
				title: '公物申购'
			}
		},{
			path: '/groupStudy',
			name: 'groupStudy',
			component: () => import('@/view/group/groupStudy'),
			meta: {
				title: '小组评分'
			}
		},{
			path: '/groupStatistics',
			name: 'groupStatistics',
			component: () => import('@/view/group/groupStatistics'),
			meta: {
				title: '小组统计'
			}
		},{
			path: '/tutor',
			name: 'tutor',
			component: () => import('@/view/manage/tutor/index'),
			meta: {
				title: '导师管理'
			}
		},{
			path: '/tutorScore',
			name: 'tutorScore',
			component: () => import('@/view/manage/tutor/score'),
			meta: {
				title: '导师管理'
			}
		},{
			path: '/tutorSet',
			name: 'tutorSet',
			component: () => import('@/view/manage/tutor/set'),
			meta: {
				title: '导师管理'
			}
		},{
			path: '/ziping',
			name: 'ziping',
			component: () => import('@/view/manage/tutor/ziping'),
			meta: {
				title: '导师管理'
			}
		},{
			path: '/tutorDetail',
			name: 'tutorDetail',
			component: () => import('@/view/manage/tutor/details'),
			meta: {
				title: '约谈详情'
			}
		},{
			path: '/groupIndex',
			name: 'groupIndex',
			component: () => import('@/view/group/index'),
			meta: {
				title: '小组配置'
			}
		},{
			path: '/myMeeting',
			name: 'myMeeting',
			component: () => import('@/view/rule/myMeeting'),
			meta: {
				title: '我的会议'
			}
		},{
			path: '/teacherCheckDetails',
			name: 'teacherCheckDetails',
			component: () => import('@/view/statement/teacherCheckDetails'),
			meta: {
				title: '教师考勤详情'
			}
		},{
			path: '/pronoun',
			name: 'pronoun',
			component: () => import('@/view/rule/pronoun'),
			meta: {
				title: '我的代课'
			}
		},{
			path: '/leaveManage',
			name: 'leaveManage',
			component: () => import('@/view/index/leaveManage'),
			meta: {
				title: '请假管理'
			}
		},{
			path: '/official',
			name: 'official',
			component: () => import('@/view/setting/official/index'),
			meta: {
				title: '微官网管理'
			}
		},{
			path: '/addArticle',
			name: 'addArticle',
			component: () => import('@/view/setting/official/addArticle'),
			meta: {
				title: '添加资讯'
			}
		},{
			path: '/setBanner',
			name: 'setBanner',
			component: () => import('@/view/setting/official/official'),
			meta: {
				title: '微官网管理'
			}
		},{
			path: '/screen',
			name: 'screen',
			component: () => import('@/view/flow/screen'),
			meta: {
				title: '电子屏申请'
			}
		},{
			path: '/chapter',
			name: 'chapter',
			component: () => import('@/view/flow/chapter'),
			meta: {
				title: '公章使用申请'
			}
		},{
			path: '/schoolbus',
			name: 'schoolbus',
			component: () => import('@/view/flow/schoolbus'),
			meta: {
				title: '校车使用申请'
			}
		},{
			path: '/ladder',
			name: 'ladder',
			component: () => import('@/view/flow/ladder'),
			meta: {
				title: '功能室申请'
			}
		},{
			path: '/ladderManage',
			name: 'ladderManage',
			component: () => import('@/view/flow/ladderManage'),
			meta: {
				title: '功能室管理'
			}
		},{
			path: '/daochu',
			name: 'daochu',
			component: () => import('@/view/manage/dorm/daochu'),
			meta: {
				title: '宿舍管理'
			}
		},{
			path: '/yujing',
			name: 'yujing',
			component: () => import('@/view/manage/dorm/yujing'),
			meta: {
				title: '宿舍管理'
			}
		},{
			path: '/plliang',
			name: 'plliang',
			component: () => import('@/view/manage/dorm/plliang'),
			meta: {
				title: '宿舍管理'
			}
		},{
			path: '/xuesheng',
			name: 'xuesheng',
			component: () => import('@/view/manage/dorm/xuesheng'),
			meta: {
				title: '宿舍管理'
			}
		},{
			path: '/dormAdd',
			name: 'dormAdd',
			component: () => import('@/view/manage/dorm/add'),
			meta: {
				title: '宿舍管理'
			}
		},{
			path: '/scheduling_auth',
			name: 'schedulingAuth',
			component: () => import('@/view/scheduling/auth/index'),
			meta: {
				title: '早读晚修配置'
			}
		}

	]
})
