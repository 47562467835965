<template>
  <div>
    <div>
      已选择{{ len }}个用户
      <el-button type="text" @click="open">选择</el-button>
    </div>

    <el-dialog :visible.sync="show" title="选择人员">
      <el-form inline @submit.native="getUserList" style="padding-top: 20px">
        <el-form-item label="名字">
          <el-input v-model="search.search" clearable />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" native-type="submit">搜索</el-button>
        </el-form-item>
      </el-form>

      <el-table ref="table" v-loading="loading" :data="list" @select="handleSelectionChange">
        <el-table-column type="selection" />
        <el-table-column label="ID" prop="id" />

        <el-table-column label="名字" prop="username" />

        <el-table-column label="手机" prop="mobile" />
      </el-table>

      <div style="padding-top: 20px">
        <el-pagination :current-page.sync="page" :total="total" :page-size="limit" />
      </div>

      <div class="footer">
        <el-button type="primary" @click="onConfirm">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'userSelect',
  props: {
    value: {
      required: true,
      type: Array,
    }
  },

  data() {
    return {
      show: false,
      search: {},
      loading: true,

      list: [],
      total: 0,

      selections: [],

      page: 1,
      limit: 10,

      pageSelections: {},
    }
  },

  computed: {
    len() {
      return this.value?.length ?? 0;
    }
  },

  watch: {
    page(val) {
      this.getUserList()
    },
    value: {
      deep: true,
      handler(val) {
        if (val) {
          this.selections = val
        } else {
          this.selections = []
        }
      }
    }
  },

  mounted() {
  },

  methods: {
    open() {
      this.show = true
      this.getUserList().then(res => {
        this.selections = this.value
        this.$set(this.pageSelections, this.page, this.selections)
        this.setSelected()
      })
    },

    setSelected() {
      this.$nextTick(() => {
        console.log('v', this.pageSelections, this.page)
        if (this.pageSelections[this.page]) {
          const values = this.list.filter(item => this.pageSelections[this.page].includes(item.id))
          values.forEach(val => {
            this.$refs.table.toggleRowSelection(val, true)
          })
        } else {
          this.$refs.table.clearSelection()
        }
      })
    },

    async getUserList() {
      this.loading = true
      try {
        const { data } = await this.$api.setting.userList({
          page: this.page,
          limit: this.total,
          ...this.search,
        })
        this.list = data.data.rows
        this.total = data.data.total
      } finally {
        this.loading = false
      }
    },

    handleSelectionChange(keys) {
      const ids = keys.map(item => item.id)

      this.$set(this.pageSelections, this.page, ids)
      const values = [...ids]
      values.push(...Object.keys(this.pageSelections).flatMap(key => {
        return this.pageSelections[key] ?? []
      }))
      this.selections = _.uniq([...values])
      console.log('k', this.selections)
      this.setSelected()
    },

    onConfirm() {
      this.$emit('input', [...this.selections])
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  padding-top: 20px;
  text-align: right;
}
</style>
