<template>
	<div id="Class" style="margin-top: 20px;">
		<div class="content" style="padding: 0">
			<div class="u-f">
				<div class="left">
					<div class="building-list">
						<div class="item" :class="items.id==grade_id?'active':''" v-for="items in gradeList" :label="items.name" :key="items.id" @click="changePart(items.id)">
							{{ items.name }}
						</div>
					</div>
				</div>
				<div class="right">
					<div class="title u-f-item u-f-jsb">
						<div>
							共{{classList.length}}条数据
						</div>
						<div class="u-f-item">
							<!-- <el-input placeholder="请输入班级名称搜索" v-model="sousuo" size="small" style="margin-right: 10px;">
								<i slot="prefix" class="el-input__icon el-icon-search"></i>
							</el-input> -->
							<el-button size="small" @click="openDialog(1)">添加班级</el-button>
              <el-button size="small" @click="importMaster = true">导入班主任</el-button>
              <!-- 宿舍导入 -->
              <el-dialog title="导入班主任" :visible.sync="importMaster" width="500px" :before-close="handleClose">
                <div class="update_box">
                  <div class="u-f" style="margin-top: 20px;">
                    <div style="color: #B7BDC0;margin-right: 10px;margin-top: 5px;">数据录入</div>
                    <div>
                      <el-button size="mini" style="position: relative;"><input ref="filElem" type="file" class="upload-file" @change="getFile">点击上传</el-button>
                      <div class="u-f-item u-f-jsb file_box" v-if="file.name" style="margin-top: 10px;width: 300px;">
                        <div class="u-f-item">
                          <i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>{{file.name}}
                        </div>
                        <i class="el-icon-circle-check" style="color: #67C23A;"></i>
                        <i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="importMaster=false" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="importMasterRequest">确 定</el-button>
			</span>
              </el-dialog>
							<!-- <el-button size="small">批量添加</el-button> -->
							<!-- <el-button size="small" icon="el-icon-s-operation">排序</el-button> -->
						</div>
					</div>
					<el-table
						:data="classList"
						height="58vh"
						ref="multipleTable"
						tooltip-effect="dark"
						style="width: 100%;border: 1px solid #EEEEEE;"
						 @selection-change="handleSelectionChange"
					>
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
						<el-table-column prop="name" label="名称"  align="center"></el-table-column>
						<!-- <el-table-column prop="grade_level_name" label="层次" column-key="grade_level_id" :filters="levelList"
						:filter-method="filterHandler"> -->
							<!-- <template slot-scope="scope">
								<div>{{scope.row.character_type==1?'不走班':'半走班'}}</div>
							</template> -->
						<!-- </el-table-column> -->
						<el-table-column prop="character_type" label="性质" column-key="character_type" :filters="[{text: '不走班', value: '1'}, {text: '半走班', value: '2'}]"
						:filter-method="filterHandler"  align="center">
							<template slot-scope="scope">
								<span class="btn" style="background: #e4f1ff;color: #1E92FF;">{{scope.row.character_type==1?'不走班':'半走班'}}</span>
							</template>
						</el-table-column>
						<!-- <el-table-column prop="university_str" label="高考走班不走学科" width="180" align="center"></el-table-column>
						<el-table-column prop="learn_str" label="学考走班不走学科" width="180" align="center"></el-table-column> -->
						<el-table-column prop="max" label="最大人数" align="center"></el-table-column>
						<el-table-column prop="student_count" label="学生人数" align="center"></el-table-column>
						<el-table-column prop="rooom" label="房间" align="center"></el-table-column>
						<el-table-column prop="teacher_one_name" label="班主任" align="center"></el-table-column>
						<el-table-column prop="remark" label="备注" align="center"></el-table-column>
						<el-table-column label="操作" width="150" align="center">
							<template slot-scope="scope">
								<el-button @click="openDialog(2, scope.row)" type="text" size="small">编辑</el-button>
								<el-button type="text" size="small" @click="openDialog(3, scope.row)">学生名单</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
						<el-button type="danger" plain size="mini" @click="delclass">删除</el-button>
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
		</div>
		<!-- 添加 编辑班级-->
		<el-dialog :title="VisibleTitle" :visible.sync="addVisible" width="500px" :before-close="handleClose">
			<template v-if="type==1||type==2">
				<!--选择 -->
				<div class="selectShow">
					<el-dialog custom-class="selectShow" :visible.sync="selectShow" width="800px" :before-close="handleClose" append-to-body>
						<div slot="title" class="u-f-item dialog-title">
							<div style="margin-right: 10px;">选择联系人</div>
						</div>
						<div class="user-list-box">
							<div class="u-f-item u-f-jsb user-list-head">
								<div class="user-type-list u-f-item">
									<div
										class="user-type-item u-f-justify"
										@click="typeTap(index)"
										:class="typeIndex == index ? 'active' : ''"
										v-for="(item, index) in teacher_list"
										:key="index"
									>
										{{ item.name }}
										<span v-if="index + 1 < teacher_list.length">|</span>
									</div>
								</div>
								<!-- <el-input placeholder="请输入内容" prefix-icon="el-icon-search" style="width: 200px;" size="small" v-model="key" @change="searchTeacher"></el-input> -->
							</div>
							<div class="user-list-content u-f">
								<div class="u-f2 left" v-if="teacher_list.length">
									<div
										class="left-item"
										@click="dataTap(index)"
										:class="dataIndex == index ? 'active' : ''"
										v-for="(item, index) in teacher_list[typeIndex].data"
										:key="index"
									>
										{{ item.name }}
									</div>
								</div>
								<div class="u-f4 right" v-if="teacher_list.length">
									<div class="list">
										<div
											class="item"
											@click="teachTap(item)"
											:class="item.checked ? 'active' : ''"
											v-for="(item, index) in teacher_list[typeIndex].data[dataIndex].teacher"
										>
											{{ item.username }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
							<el-button @click="reset" size="small">重置</el-button>
							<div class="u-f-item">
								<!-- <span style="color: #A4A4A4;margin-right: 10px;">{{ teachers.length }}/{{ nums.length }}</span> -->
								<el-button type="primary" size="small" @click="selectShow = false">确 定</el-button>
							</div>
						</span>
					</el-dialog>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							名称
						</div>
						<el-input v-model="name" placeholder="请输入班级名称" style="width: 80%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal"><span>*</span>性质</div>
						<el-radio-group v-model="character_type">
							<el-radio-button label="1">不走班</el-radio-button>
							<el-radio-button label="2">半走班</el-radio-button>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal"><span>*</span>班主任</div>
						<div class="u-f-item input-item" style="width: 80%;">
							<el-select v-model="teachers" popper-class="el_option" style="width: 66%;">
								<el-option style="display: none;" v-for="(item, index) in selectList" :key="index" :label="item.username" :value="item.teacher_id"></el-option>
							</el-select>
							<el-button style="margin-left: 10px;" @click="selectShow = true">选择</el-button>
						</div>
					</div>
					<!-- <blockquote style="margin: 0" v-if="character_type==2">
						<div class="u-f-item input-box">
							<div class="input-lebal"><span>*</span>高考走班不走学科</div>
							<el-select v-model="gaokao" multiple placeholder="请选择学科" style="width: 80%;">
								<el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
						<div class="u-f-item input-box">
							<div class="input-lebal"><span>*</span>学考走班不走学科</div>
							<el-select v-model="xuekao" multiple placeholder="请选择学科" style="width: 80%;">
								<el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
					</blockquote> -->
					<div class="u-f-item input-box">
						<div class="input-lebal">
							最大人数
						</div>
						<el-input v-model="max" placeholder="请输入最大人数" style="width: 80%;"></el-input>
					</div>
					<!-- <div class="u-f-item input-box">
						<div class="input-lebal">层次</div>
						<el-select v-model="grade_level_id" placeholder="请选择层次" style="width: 80%;">
							<el-option v-for="item in levelList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div> -->
					<div class="u-f-item input-box">
						<div class="input-lebal">房间</div>
						<el-select v-model="school_place_room_id" multiple placeholder="请选择房间" style="width: 80%;">
							<el-option v-for="item in roomList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							备注
						</div>
						<el-input v-model="remark" placeholder="请输入备注" style="width: 80%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeVisible" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</template>
			<template v-else>
				<div class="header u-f-item u-f-jsb">
					<div class="u-f-item">
						<span>共{{studentList.length}}人</span>
					</div>
				</div>
				<div class="con-box">
					<div class="u-f-item con-item" v-for="(item, index) in studentList" :key="index">
						<div class="user-pic u-f-justify" :class="item.sex==1?'':'nv'">{{item.realname[0]}}</div>
						<div>{{item.realname}}</div>
					</div>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		condition: {
			type: Number,
			default: 0
		},
		year: {
			type: Number,
			default: 0
		},
		semester: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
      importMaster: false,
			sousuo: '',
			classList: [],
			page: 1,
			limit: 10,
			total: 0,
			VisibleTitle: '',
			addVisible: false,
			type: -1,
			id: '',
			ids: [],
			name: '',
			character_type: 1,
			gaokao: [1],
			xuekao: [1],
			grade_level_id: '',
			max: '',
			remark: '',
			school_place_room_id: '',
			levelList: [],
      file: {},
			roomList: [],
			grade_id: '',
			subjectList: [],
			gradeList: [],
			studentList: [],
			teachers:'',
			selectShow:false,
			selectList:[],
			teacher_list:[],
			key: '',
			typeIndex: 0,
			dataIndex: 0,
		};
	},
	watch: {
		year: {
			immediate: true,
			handler(value) {
				this.year = value;
				this.gradeData()
			}
		},
		semester: {
			immediate: true,
			handler(value) {
				this.semester = value;
				this.gradeData()
			}
		}
	},
	created() {
		this.gradeData();
		this.getSubjectList();
		this.levelData();
		this.getRoomList()
		this.teacherList();
	},
	methods: {
    getFile(e){
      if(this.$refs.filElem.files.length){
        this.file = this.$refs.filElem.files[0]
      }
    },
    delFile(){
      this.file = {};
      this.$refs.filElem.value = '';
    },
    importMasterRequest() {
      let myfile = this.$refs.filElem;
      let files = myfile.files;
      let file = files[0];
      var formData = new FormData();
      formData.append("file", file);
      this.$api.setting.classMasterImport(formData).then(res=>{
        if(res.data.code==1){
          this.$message.success('导入成功');
          this.getPlaceRoomList()
          this.file = {};
          this.importMaster = false;
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },

		dataTap(index){
			this.dataIndex = index;
		},
		typeTap(index){
			this.typeIndex = index;
		},
		handleSelectionChange(val) {
			let arr = [];
			for(let i in val){
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids)
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.classData()
		},
		handleClose(done){
			this.initialize()
			done()
		},
		changePart(id){
			this.grade_id = id;
			this.classData();
		},
		filterHandler(value, row, column) {
			const property = column['property'];
			return row[property] == value;
		},
		// 初始化表单
		initialize(){
			this.name = '';
			this.character_type = 1;
			this.gaokao = [];
			this.xuekao = [];
			this.grade_level_id = '';
			this.max = '';
			this.actual = '';
			this.remark = '';
			this.school_place_room_id = '';
		},
		// 年级列表
		gradeData(){
			this.$api.setting.getGradeList({filter: JSON.stringify({ 'year_id': this.year})}).then(res=>{
				if(res.data.code==1){
					this.gradeList = res.data.data.rows;
					this.grade_id = this.gradeList.length?this.gradeList[0].id: '';
					this.classData();
				}
			})
		},
		// 班级列表
		classData(){
			this.$api.setting.getClassList({
				limit: this.limit,
				page: this.page,
				filter: JSON.stringify({'grade_id': this.grade_id, 'semester_id': this.semester})
			}).then(res=>{
				this.classList = res.data.rows;
				this.total = res.data.total;
			})
		},
		// 学科列表
		getSubjectList(){
			this.$api.setting.subjectList({}).then(res=>{
				if(res.data.code==1){
					this.subjectList = res.data.data;
				}
			})
		},
		// 层次列表
		levelData(){
			this.$api.setting.getLevelList({}).then(res=>{
				if(res.data.code==1){
					let list = res.data.data.rows;
					for(let i=0;i<list.length;i++){
						list[i].text = list[i].name;
						list[i].value = list[i].id;
					}
					this.levelList = list
				}
			})
		},
		// 房间列表
		getRoomList(){
			this.$api.setting.getPlaceRoomList({}).then(res=>{
				if(res.data.code==1){
					this.roomList = res.data.data.room.rows;
				}
			})
		},
		// 学生名单列表
		getStudentList(item){
			this.$api.setting.classStudent({class_id: item.id}).then(res=>{
				if(res.data.code==1){
					this.studentList = res.data.data;
				}
			})
		},
		// 弹窗
		openDialog(type,item){
			this.type = type;
			if(type==1){
				this.VisibleTitle = '添加班级';
			}else if(type==2){
				this.VisibleTitle = '编辑班级';
				this.id = item.id;
				this.name = item.name;
				this.character_type = item.character_type;
				this.gaokao = item.university_id;
				this.xuekao = item.learn_id;
				this.grade_level_id = item.grade_level_id;
				this.max = item.max;
				this.remark = item.remark;
				this.school_place_room_id = item.rooom_id;
				this.teachers = item.teacher_one;
			}else if(type==3){
				this.VisibleTitle = item.name;
				this.getStudentList(item)
			}
			this.addVisible = true;
		},
		// 关闭弹窗
		closeVisible(){
			this.initialize()
			this.addVisible = false;
		},
		// 添加、编辑班级
		submit(){
			// if(!this.condition||this.condition==0) return this.$message.error('请先前往添加校区');
			if(!this.semester||this.semester==0) return this.$message.error('请先前往添加学年学期');
			if(!this.grade_id||this.grade_id==0) return this.$message.error('请先添加年级');
			if(this.type==1){
				this.addclass()
			}else if(this.type==2){
				this.editclass()
			}
			this.initialize()
		},
		// 添加班级
		addclass(){
			this.$api.setting.addClass({
				// school_campus_id: this.condition,
				name: this.name,
				character_type: this.character_type,
				gaokao: this.gaokao,
				xuekao: this.xuekao,
				grade_level_id: this.grade_level_id,
				max: this.max,
				remark: this.remark,
				school_place_room_id: this.school_place_room_id,
				grade_id: this.grade_id,
				semester_id: this.semester,
				teacher_one:this.teachers
			}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.classData();
					this.addVisible = false;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 编辑班级
		editclass(){
			this.$api.setting.editClass({
				id: this.id,
				// school_campus_id: this.condition,
				name: this.name,
				character_type: this.character_type,
				gaokao: this.gaokao,
				xuekao: this.xuekao,
				grade_level_id: this.grade_level_id,
				max: this.max,
				remark: this.remark,
				school_place_room_id: this.school_place_room_id,
				grade_id: this.grade_id,
				semester_id: this.semester,
				teacher_one:this.teachers
			}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.classData();
					this.addVisible = false;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 删除班级
		delclass(){
			let _this = this;
			if(this.ids.length){
				this.$confirm('确定要删除？','提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(()=>{
					this.$api.setting.delClass({
						id:this.ids
					}).then(res=>{
						if (res.data.code == 1) {
							_this.$message.success('删除成功');
							_this.classData();
						} else {
							_this.$message.error(res.data.msg);
						}
					})
				})
			}else{
				this.$message.error('请选择要删除的班级')
			}
		},
		// 教师列表
		teacherList() {
			this.$api.pepople.teacherList({}).then(res => {
				if (res.data.code == 1) {
					this.teacher_list = res.data.data;
					for (let i in this.teacher_list) {
						for (let j in this.teacher_list[i].data) {
							for (let t in this.teacher_list[i].data[j].teacher) {
								this.selectList = this.selectList.concat(this.teacher_list[i].data[j].teacher[t]);
							}
						}
					}
					// this.nums = this.unique(this.nums);
					this.selectList = this.unique2(this.selectList);
				}
			});
		},
		// 重置
		reset() {
			this.teachers = '';
			this.teacherList();
		},
		teachTap(item) {
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teacher_list[i].data[j].teacher[t].teacher_id == item.teacher_id && !this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = true;
						} else{
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
					}
				}
			}
			this.teachers = item.teacher_id;
		},
		unique(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr) && res.set(arr, 1));
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},
	}
};
</script>

<style lang="scss" >
#Class{
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 73vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 73vh;
			width: 15%;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-bottom: 15px;
					background-color: #f8f8f8;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.item:hover{
					color: #007aff;
					background: #e4f1ff;
					cursor: pointer;
				}
				.active{
					color: #007aff;
					background: #e4f1ff;
				}
			}
		}
		.right {
			width: 80%;
			height: 75vh;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
			.el-table{
				table-layout: fixed;
			}
			.el-table th > .cell,
			.el-table .cell {
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*不换行*/
				text-overflow: ellipsis; /*超出部分文字以...显示*/
			}
			.el-table thead {
				background: #fafafa !important;
			}
			.el-table th {
				padding: 15px 5px !important;
				background: #fafafa !important;
			}
			.el-table::before {
				left: 0;
				bottom: 0;
				width: 100%;
				height: 0;
			}
			.el-table .btn{
				padding: 5px 10px;
				text-align: center;
				font-size: 12px;
			}
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 15%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.input-item{
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}
					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}
					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
		.header{
			padding: 10px 0;
			font-weight: bold;
			font-size: 16px;
		}
		.con-box{
			display: flex;
			flex-wrap: wrap;
			max-height: 60vh;
			overflow-y: scroll;
			.con-item{
				width: 120px;
				background: #f1f1f1;
				margin-right: 10px;
				margin-bottom: 10px;
				padding: 10px;
				border-radius: 10px;
				.user-pic{
					width: 40px;
					height: 40px;
					border-radius: 50%;
					background-color: #007AFF;
					color: #FFFFFF;
					margin-right: 10px;
				}
				.nv{
					background-color: #CDAFB8;
				}
			}
		}
		.con-box>div:nth-child(3n){
			margin-right: 0;
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}

}
.selectShow {
		.el-dialog__header {
			border-bottom: 1px solid #eeeeee;
			padding: 10px 20px;
			.el-dialog__title {
				font-size: 16px;
			}
		}
		.el-dialog__body {
			padding: 0;
			.user-list-box {
				.user-list-head {
					padding: 10px 20px;
					border-bottom: 1px solid #eeeeee;
					.user-type-list {
						.user-type-item {
							color: #8d9da6;
							cursor: pointer;
						}
						.active {
							color: #007aff;
							position: relative;
						}
						.active::after {
							position: absolute;
							width: 25px;
							height: 2px;
							background-color: #007aff;
							content: '';
							bottom: -5px;
							left: 0;
						}
						span {
							color: #cccccc;
							margin: 0 8px;
						}
					}
				}
				.user-list-content {
					height: 40vh;
					.left {
						height: 98%;
						overflow-y: auto;
						border-right: 1px solid #eeeeee;
						padding-top: 5px;
						.left-item {
							padding: 0 20px;
							height: 40px;
							line-height: 40px;
							color: #a4a4a4;
							cursor: pointer;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
							position: relative;
						}
						.active:after {
							position: absolute;
							top: 0;
							bottom: 0;
							right: 0;
							width: 2px;
							content: '';
							background-color: #2d8cf0;
						}
					}
					.right {
						height: 37vh;
						padding: 10px 25px;
						overflow-y: auto;
						.list {
							display: flex;
							flex-wrap: wrap;
							.item {
								cursor: pointer;
								padding: 5px 15px;
								border-radius: 3px;
								background-color: #f3f3f3;
								margin-right: 10px;
								margin-bottom: 10px;
							}
							.active {
								background-color: #e4f1ff;
								color: #2d8cf0;
							}
						}
					}
				}
			}
		}
		.dialog-title {
			padding: 10px 0 5px 0;
		}
		.el-dialog__footer {
			padding: 15px 20px 15px;
			box-shadow: 0px -5px 10px -5px #eeeeee;
		}
	}
</style>
