<template>
  <div id="rewards">
    <index>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>基础配置</el-breadcrumb-item>
        <el-breadcrumb-item><a style="color: #000000;">早读晚修配置</a></el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content">
        <el-form>
          <el-form-item label="高一">
            <user-select v-model="form['1']" />
          </el-form-item>

          <el-form-item label="高二">
            <user-select v-model="form['2']" />
          </el-form-item>

          <el-form-item label="高三">
            <user-select v-model="form['3']" />
          </el-form-item>
        </el-form>
      </div>
    </index>

  </div>
</template>

<script>
import index from '@/components/common/index.vue';
import UserSelect from "@/components/userSelect/index.vue";
export default {
  components: {
    UserSelect,
    index
  },
  data() {
    return {
      form: {
        '1': [],
        '2': [],
        '3': []
      }
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  margin-top: 20px;
  background-color: #ffffff;
  height: 78vh;
}
</style>
